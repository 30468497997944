import React from 'react';
import { Link } from 'gatsby';
import moment from 'moment';
import './style.scss';

class ProjectTemplateDetails extends React.Component {
	render() {
		const { subtitle, author } = this.props.data.site.siteMetadata;
		const project = this.props.data.markdownRemark;

		const homeBlock = (
			<div>
				<Link className="project-single__home-button" to="/">
					All Articles
				</Link>
			</div>
		);

		return (
			<div>
				{homeBlock}
				<div className="project-single">
					<div className="project-single__inner">
						<h1 className="project-single__title">{project.frontmatter.title}</h1>
						<div
							className="project-single__body"
							/* eslint-disable-next-line react/no-danger */
							dangerouslySetInnerHTML={{ __html: project.html }}
						/>
						<div className="project-single__date">
							<em>
								Published {moment(project.frontmatter.date).format('D MMM YYYY')}
							</em>
						</div>
					</div>
					<div className="project-single__footer">
						<hr />
						<p className="project-single__footer-text">
							{subtitle}
							<a
								href={`https://bsky.app/profile/${author.bluesky}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<br /> <strong>{author.name}</strong> on Bluesky
							</a>
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default ProjectTemplateDetails;
